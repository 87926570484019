import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';

const ability = new Ability();

import '@/helpers/ag-grid-enterprise';
import '@/helpers/ag-grid-enterprise/styles/ag-grid.css';
import '@/helpers/ag-grid-enterprise/styles/ag-theme-alpine.css';

import { AgGridVue } from 'ag-grid-vue3';

import flatPickr from "vue-flatpickr-component";
import flatpickr from "flatpickr"; // Core flatpickr library

import { formatDateTime, formatAmount, unformatAmount } from '@/helpers/utilities';

import Multiselect from "@vueform/multiselect/src/Multiselect";
import "@vueform/multiselect/themes/default.css";

flatpickr.setDefaults({
    allowInput: true,
    altInput: true,
    altFormat: 'd-m-Y', // Display format: "December 17, 2024"
    dateFormat: 'Y-m-d', // Underlying format for Flatpickr
});

const app = createApp(App);

app.config.globalProperties.$formatDateTime = formatDateTime;
app.config.globalProperties.$formatAmount = formatAmount;
app.config.globalProperties.$unformatAmount = unformatAmount;

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

app
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    })
    .component('AgGridVue', AgGridVue)
    .component('Multiselect', Multiselect)
    .component('flatPickr', flatPickr)
    .mount('#app');